:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.list-item-adv {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 1px 17px 0px rgba(0, 0, 0, 0.12);
  padding: 5px 20px;
}
.list-item-adv .list-tip-adv {
  padding-top: 20px;
  font-size: 22px;
  line-height: 30px;
}
.list-item-adv .list-content-adv {
  border-top: 2px solid #c78a29;
  margin: 20px;
}
.list-item-adv .list-content-adv .list-title-adv {
  margin: 20px 0px 15px 0px;
  font-weight: bold;
  font-size: 15px;
  overflow: hidden;
}
.list-item-adv .list-content-adv .list-text-adv {
  height: 35px;
}
.list-item-adv .list-media-adv {
  width: 100%;
  height: 100px;
  padding: 15px;
}
.list-item-adv .list-media-adv img {
  object-fit: contain;
  width: 100px;
  height: 100px;
}